import React from 'react';
import '../../styles/home.css';

class CardContent extends React.Component {

    render () {
        return (
            <div className="card-container container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="step-content-one">
                            <div className="step-title">
                                <b>Step 1</b> <br />
                                Enroll in original Medicare
                            </div>
                            <div className="card card-costume">
                                <div className="card-title black">
                                    <b>Original Medicare</b> <br />
                                    Provided by the federal government
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-1"></div>
                                        <div className="col-sm-3">
                                            <b>PART A</b>
                                            <br />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                                                <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                                            </svg>
                                        </div>
                                        <div className="col-sm-7">
                                            <b>HOSPITAL</b> <br />
                                            Helps cover inpatient <br />
                                            care in hospitals
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-1"></div>
                                        <div className="col-sm-3">
                                            <b>PART B</b> 
                                            <br />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-clipboard-plus" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
                                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                                            </svg>
                                        </div>
                                        <div className="col-sm-7">
                                            <b>MEDICAL</b> <br />
                                            Helps cover services<br />
                                            from doctors and other<br />
                                            healthcare providers
                                        </div>
                                    </div>
                                    <br />                                                        
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8 step-content-two">
                        <div className="step-title">
                            <b>Step 2</b> <br />
                            After you enroll in original Medicare, <br />there are two ways to get additional coverage
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="step-title">
                                    <b>Option 1</b> <br />
                                    Add one or both of the following
                                </div>
                                <div className="card">
                                    <div className="card-title red">
                                    <b>Medicare Supplement Insurance</b> <br />
                                    Offered by private companies
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-1"></div>
                                            <div className="col-sm-3">
                                                <br />
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="lightcoral" class="bi bi-umbrella" viewBox="0 0 16 16">
                                                    <path d="M8 0a.5.5 0 0 1 .5.5v.514C12.625 1.238 16 4.22 16 8c0 0 0 .5-.5.5-.149 0-.352-.145-.352-.145l-.004-.004-.025-.023a3.484 3.484 0 0 0-.555-.394A3.166 3.166 0 0 0 13 7.5c-.638 0-1.178.213-1.564.434a3.484 3.484 0 0 0-.555.394l-.025.023-.003.003s-.204.146-.353.146-.352-.145-.352-.145l-.004-.004-.025-.023a3.484 3.484 0 0 0-.555-.394 3.3 3.3 0 0 0-1.064-.39V13.5H8h.5v.039l-.005.083a2.958 2.958 0 0 1-.298 1.102 2.257 2.257 0 0 1-.763.88C7.06 15.851 6.587 16 6 16s-1.061-.148-1.434-.396a2.255 2.255 0 0 1-.763-.88 2.958 2.958 0 0 1-.302-1.185v-.025l-.001-.009v-.003s0-.002.5-.002h-.5V13a.5.5 0 0 1 1 0v.506l.003.044a1.958 1.958 0 0 0 .195.726c.095.191.23.367.423.495.19.127.466.229.879.229s.689-.102.879-.229c.193-.128.328-.304.424-.495a1.958 1.958 0 0 0 .197-.77V7.544a3.3 3.3 0 0 0-1.064.39 3.482 3.482 0 0 0-.58.417l-.004.004S5.65 8.5 5.5 8.5c-.149 0-.352-.145-.352-.145l-.004-.004a3.482 3.482 0 0 0-.58-.417A3.166 3.166 0 0 0 3 7.5c-.638 0-1.177.213-1.564.434a3.482 3.482 0 0 0-.58.417l-.004.004S.65 8.5.5 8.5C0 8.5 0 8 0 8c0-3.78 3.375-6.762 7.5-6.986V.5A.5.5 0 0 1 8 0zM6.577 2.123c-2.833.5-4.99 2.458-5.474 4.854A4.124 4.124 0 0 1 3 6.5c.806 0 1.48.25 1.962.511a9.706 9.706 0 0 1 .344-2.358c.242-.868.64-1.765 1.271-2.53zm-.615 4.93A4.16 4.16 0 0 1 8 6.5a4.16 4.16 0 0 1 2.038.553 8.688 8.688 0 0 0-.307-2.13C9.434 3.858 8.898 2.83 8 2.117c-.898.712-1.434 1.74-1.731 2.804a8.687 8.687 0 0 0-.307 2.131zm3.46-4.93c.631.765 1.03 1.662 1.272 2.53.233.833.328 1.66.344 2.358A4.14 4.14 0 0 1 13 6.5c.77 0 1.42.23 1.897.477-.484-2.396-2.641-4.355-5.474-4.854z"/>
                                                </svg>
                                            </div>
                                            <div className="col-sm-7">
                                                <b>Medicare Supplement</b> <br />
                                                Helps pay some of the out-of-pocket
                                                costs that come with Original Medicare
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="card">
                                    <div className="card-title red">
                                        <b>Medicare Part D Plan</b> <br />
                                        Offered by private companies
                                    </div>
                                    <div className="card-body">
                                    <div className="row">
                                            <div className="col-sm-1"></div>
                                            <div className="col-sm-3">
                                                <br />
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="lightcoral" class="bi bi-eyedropper" viewBox="0 0 16 16">
                                                    <path d="M13.354.646a1.207 1.207 0 0 0-1.708 0L8.5 3.793l-.646-.647a.5.5 0 1 0-.708.708L8.293 5l-7.147 7.146A.5.5 0 0 0 1 12.5v1.793l-.854.853a.5.5 0 1 0 .708.707L1.707 15H3.5a.5.5 0 0 0 .354-.146L11 7.707l1.146 1.147a.5.5 0 0 0 .708-.708l-.647-.646 3.147-3.146a1.207 1.207 0 0 0 0-1.708l-2-2zM2 12.707l7-7L10.293 7l-7 7H2v-1.293z"/>
                                                </svg>
                                            </div>
                                            <div className="col-sm-7">
                                                <b>Part D</b> <br />
                                                Helps pay for prescription drugs
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="step-title">
                                    <b>Option 2</b> <br />
                                    Choose a Medicare Advantage Plan
                                </div>
                                <div className="card">
                                    <div className="card-title blue">
                                    <b>Medicare Advantage Plan</b> <br />
                                    Offered by private companies
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-1"></div>
                                            <div className="col-sm-3">
                                                <br />
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="lightblue" class="bi bi-building" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                                                    <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                                                </svg>
                                                <br />
                                                <br />
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="lightblue" class="bi bi-clipboard-plus" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
                                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                                                </svg>
                                            </div>
                                            <div className="col-sm-7">
                                                <b>Part C</b> <br />
                                                Combines Part A (hospital insurance) and Part B (medical insurance)
                                                in one plan
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-sm-1"></div>
                                            <div className="col-sm-3">
                                                <br />
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="lightblue" class="bi bi-eyedropper" viewBox="0 0 16 16">
                                                    <path d="M13.354.646a1.207 1.207 0 0 0-1.708 0L8.5 3.793l-.646-.647a.5.5 0 1 0-.708.708L8.293 5l-7.147 7.146A.5.5 0 0 0 1 12.5v1.793l-.854.853a.5.5 0 1 0 .708.707L1.707 15H3.5a.5.5 0 0 0 .354-.146L11 7.707l1.146 1.147a.5.5 0 0 0 .708-.708l-.647-.646 3.147-3.146a1.207 1.207 0 0 0 0-1.708l-2-2zM2 12.707l7-7L10.293 7l-7 7H2v-1.293z"/>
                                                </svg>
                                            </div>
                                            <div className="col-sm-7">
                                                <b>Part D</b> <br />
                                                Usually includes prescription drug coverage
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-sm-1"></div>
                                            <div className="col-sm-3">
                                                <br />
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="lightblue" class="bi bi-search" viewBox="0 0 16 16">
                                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                                </svg>
                                            </div>
                                            <div className="col-sm-7">
                                                May offer additional benefits not provided by Original Medicare
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default CardContent;