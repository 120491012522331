import React from 'react';
import '../styles/nav_style.css';

class Footer extends React.Component {

    render () {
        return (
            <div className="footer-container">
               2021 Medical Store For You
            </div>
        );
    }
}

export default Footer;