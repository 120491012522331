import React from 'react'
import '../../styles/appointment.css'

class Appointment extends React.Component {

    render() {
        return (
            <div className="container">
                <div className="schedule-container">
                    <div className="schedule-box">
                        <iframe src="https://themedicarestoreforyou.setmore.com" scrolling="yes" width="100%" height="100%" frameborder="0"></iframe>
                    </div>
                </div>
            </div>
    );}
}

export default Appointment