import React from 'react';
import '../styles/nav_style.css';
import Link from './Link';
import logo from '../images/logo.png';

class Navigation extends React.Component {

    render () {
        return (
            <div className="nav-container">
                <ul className="nav justify-content-center">
                    <li className="nav-item">
                        <a class="navbar-brand" href="/">
                            <img src={logo} height="30" alt=""/>
                        </a>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-button" href="/">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-button" href="#">About us</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-button schedule-button" href="/appointment">Schedule An Appointment</Link>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Navigation;