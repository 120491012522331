import React from 'react';
import Navigation from './Navigation';
import Header from './home/Header';
import HeaderContent from './home/HeaderContent';
import CardContent from './home/CardContent';
import Footer from './Footer';
import Appointment from './appointment/Appointment';
import Route from './Route';

class App extends React.Component {

    // use the grid system here 
    render () {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <Navigation />
                        </div>
                    </div>
                    <Route path="/">
                        <div className="row">
                            <Header />
                        </div>
                        <div className="row">
                            <CardContent />
                        </div>
                        <div className="row">
                            <HeaderContent />
                        </div>
                    </Route>
                    <Route path="/appointment">
                        <div className="row">
                            <Appointment />
                        </div>
                    </Route>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default App;