import React from 'react';
import header_image from '../../images/header_picture.jpeg';

class Header extends React.Component {

    render () {
        return (
            <div className="head-container">
                <div className="row">
                    <div className="header-content fs-1 col-md-5">
                        <h1>
                        THE <span>MEDICARE</span> STORE<br/>
                        FOR YOU <br/>
                        </h1>

                        <h3>
                        LA TIENDA DE <span>MEDICARE</span> PARA USTED
                        </h3>

                        <h5>
                        <span>ONE PLACE</span> <br/>
                        and <br/>
                        <span>MANY</span> OPTIONS <br/>
                        </h5>

                        <h6>Offers impartial Medicare advice <span>AT NO COST TO YOU</span></h6>
                        <h6><span>La Tienda de Medicare para Usted Llamenos</span></h6>
                        <h5><span>(866)370-0104</span></h5>
                        <h6><span>3865 S Wasatch Blvd, Unit 140</span></h6>
                        <h6><span>Salt Lake City, UT 84109</span></h6>
                    </div>
                    <div className="header-image col-md-6">
                        <div >
                            <img src={header_image} className="main-image img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;