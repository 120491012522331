import React from 'react';
import '../../styles/home.css';
import card_sample from '../../images/card_sample.png';

class HeaderContent extends React.Component {

    render () {
        return (
            <div className="row">
                <div className="col-md-8">
                    <div className="list-title">
                        <h1>What we offer?</h1>
                    </div>
                    <div className="head-list-container">
                        <ul class="head-list">
                            <li>
                                We are local licenced independant insurance agents <br />
                                Somos agentes  locales, en Utah  Licenciados y especializados en los planes de Medicare con todas las compañías Nacionales
                            </li>
                            <li>We explain how Medicare works at no cost to you <br />
                                Explicamos Cómo funciona el programa de Medicare y Usted Elige sus beneficios sin costo y sin compromiso
                            </li>
                            <li>We show your Medicare plan and prescription drug options <br />
                                Explicamos el plan de Medicare y las diferentes opciones referentes a medicamentos
                            </li>
                            <li>
                                Dental - Vision You choose your plan <br />
                                Planes de Beneficio Dental y Oculista
                            </li>
                            <li>
                                Extra help for limited income (LIS) <br />
                                El cliente recibe ayuda extra si sus ingresos son limitados (LIS)
                            </li>
                            <li>
                                Hospital Indemnity <br />
                                Indemnización hospitalaria
                            </li>
                            <li>
                                Medicare part “B” refund <br />
                                Reembolso del costo de la de la parte B de Medicare
                            </li>
                            <li>
                                Final expences <br />
                                Gastos Funerarios
                            </li>
                            <li>Se habla español</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 container">
                    <div className="row">
                        <div class="sample-image">
                            <img src={card_sample} className="img-fluid"/>
                        </div>
                    </div>
                    <div className="row card-info">
                        <div class="card card-info">
                        <b>The Medicare Store For You</b>
                        Not affiliated with any government agency.
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-signpost-fill" viewBox="0 0 16 16">
                            <path d="M7.293.707A1 1 0 0 0 7 1.414V4H2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h5v6h2v-6h3.532a1 1 0 0 0 .768-.36l1.933-2.32a.5.5 0 0 0 0-.64L13.3 4.36a1 1 0 0 0-.768-.36H9V1.414A1 1 0 0 0 7.293.707z"/>
                        </svg>
                        Mail to<br />
                        PO Box 17869<br />
                        Salt Lake City, UT 84117 <br />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                        </svg>
                        (866) 370-0104 <br /> 
                        </div>
                    </div>
                    <div className="row  card-info">
                        <div className="card card-info">
                            <span><b>IMPORTANT COVID-19 SAFETY NOTE:</b></span>
                            Place Stamp Here
                            <b>OFFERED ONLINE OR IN PERSON</b><br />
                            For everyone’s health and safety at in person classes, 
                            social distancing protocols will be followed and seating will be limited in compliance with CDC, state, and local guidelines.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeaderContent;